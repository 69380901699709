@import "../../../sass/_variables.scss";

h1 {
    margin-bottom: $spacer-small
}

rl-onboarding-container-component {
    box-sizing: border-box;
    transition: 0.3s ease-in all;
    opacity: 0;
    display: grid;

    .gradient-background {
        background-image: url('/assets/images/logo_relayter_mark_white.svg'), $background-gradient;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 70%, cover;
        width: calc(5/12*100%);
        position: fixed;
        height: 100%;
    }

    .login-register-page-block__inner {
        margin-left: calc(5/12*100%);
        margin-top: $spacer-extra-extra-large;
        margin-bottom: $spacer-large;

        .login-register-page-block__inner__container {
            width: $form-width;
            margin: auto auto;
            background: $color_white;
            padding: $spacer-base;
        }
    }
}

rl-onboarding-container-component.loaded {
    opacity: 1;
}
